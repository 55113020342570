.database-view {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.database-nav {
  display: flex;
}

.database-nav select {
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 10px;
}

.database-nav-results {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-right: 20px;
}

.database-nav-show {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-right: 20px;
}

.database-nav-page {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-right: 20px;
}

.database-nav-previous {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.database-nav-next {
  display: flex;
  align-items: center;
}

.database-table {
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  margin: 16px auto 16px auto;
}

.database-table-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  font-size: 2rem;
  font-weight: 700;
  color: #A0A0A0;
}

.database-table-header {
  background-color: #3bb54a;
  color: #ffffff;
  font-weight: 700;
  padding: 8px;
  border-radius: 10px 10px 0 0;
}

.database-table-header,
.database-table-header thead,
.database-table-header tr {
  background-color: #3bb54a;
  color: #ffffff;
}

.database-table-header-row {
  border-radius: 10px;
}

.database-table-header-item {
  padding: 8px;
}
.database-table-header-item:first-child { padding: 8px 8px 8px 20px }
.database-table-header-item:last-child { padding: 8px 20px 8px 8px }

.database-table-row {

}
.database-table-row:nth-child(odd)  { background-color: #EAEAEA }
.database-table-row:nth-child(even) { background-color: #FFFFFF }
.database-table-row:last-child      { border-radius: 0 0 10px 10px }

.database-table-item {
  padding: 8px;
}
.database-table-item:first-child { padding: 8px 8px 8px 20px }
.database-table-item:last-child { padding: 8px 20px 8px 8px }

.database-table-item a {
  text-decoration: none;
  color: #3B88FD;
}
