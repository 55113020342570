.production-report {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.production-report-stats {
  height: 100%;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1rem;
  margin-right: 40px;
}

.production-report-select-hub {
  display: flex;
  align-items: center;
}

.production-report-select-hub p {
  font-weight: 700;
  margin-right: 10px;
}

.production-report-week {
  margin-top: 0;
}

.production-report-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.production-report-table-section {
  margin-bottom: 1.25rem;
}

.production-report-table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 200px 60px auto;
  grid-row-gap: 0;
}

.production-report-table-row p {
  /* margin: 0.6rem 0.6rem 0.6rem 0; */
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
}

.production-report-header {
  /* font-weight: 700; */
}

.production-report-stat {

}

.production-report-units {
  font-size: 0.8rem;
  color: #a1a1a1;
}

.production-report-chart {
  display: flex;
  align-self: center;
  flex-direction: column;
  /* width: 300px;
  min-width: 300px;
  height: 200px; */
  /* border: 1px solid #a5a5a5;
  border-radius: 10px; */
}




.production-report-overlay {
  fill: none;
  pointer-events: all;
}

.production-report-tooltip {
  /* width: 94px; */
  padding: 4px 10px;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  position: absolute;
  background-color: white;
  font-size: 14px;
  pointer-events: none;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.production-report-tooltip div {
  margin: 3px 0;
}

.focus circle {
  fill: #3bb54a;
}

.production-report-tooltip-xValue, .production-report-tooltip-yValue {
  font-weight: bold;
}







.production-report-chart-img {
  background-color: #ffffff;
  width: 300px;
  min-width: 300px;
  height: 200px;
  border: 1px solid #a5a5a5;
  border-radius: 10px;
}
