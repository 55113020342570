.sidebar-main {
  min-height: 100vh;
  min-width: 150px;
  width: 160px;                   /* NEW */
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sidebar-main .logo-esb {
  height: 130px;
  width: 130px;
}

.sidebar-nav-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  padding: 5px 20px;
  cursor: pointer;
}

a.sidebar-nav-item {
  text-decoration: none;
  color: inherit;
}

a.sidebar-nav-item[active=true] {
  color: #3B88FD;
}

.sidebar-nav-item-name {
  padding-left: 10px;
}

.sidebar-nav-item-name-active {
  font-weight: 700;
  color: #3585FF;
  padding-left: 10px;
}
