.input-wrapper {
  margin-bottom: 16px;
}

.input-container {
  /* display: flex;
  flex-direction: column; */
  width: 300px;
  height: 40px;
  border: 2px solid #767676;
  border-radius: 5px;
  position: relative;
  transition: 0.3s all;
}

.input-label {
  color: #767676;
  position: absolute;
  bottom: 0;
  padding: 0 10px 0 10px;
  border-radius: 5px;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  /* transition-property: color, font-size, height, bottom;
  transition-duration: 1s; */
  transition: 0.1s all;
  z-index: 2;
}

.input-container input {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 5px;
  position: relative;
  border-style: hidden;
  padding-left: 10px;
  z-index: 1;
}

.input-icon-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3px;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: text;
}

.input-icon-container .input-icon {
  display: none;
  position: relative;
  z-index: 3;
  height: 0;
  width: 0;
  cursor: default;
  /* transition: transform 1s ease-in-out; */
}

.input-description {
  z-index: 4;
  margin: 7px 0 0 11px;
  text-align: left;
  font-size: 0.75rem;
}
