.profile-info {
  padding: 10px 0 24px 0;
}

.profile-info-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.profile-info-icon {
  position: relative;
  margin-right: 20px;
  height: 80px;
}

.profile-info-icon-icon {
  z-index: 1;
}

.profile-info-icon-overlay {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 6px;
  font-size: 0.8rem;
  background-color: rgba(0,0,0,0.75);
  color: #FFFFFF;
  border-radius: 0 0 80px 80px;
  z-index: 2;
}

.profile-info-name {
  display: flex;
  flex-direction: column;
}

.profile-info-display-name {
  font-size: 1.75rem;
  color: #2E2E2E;
}

.profile-info-title {
  font-size: 1.375rem;
  color: #656565;
}

.profile-info-table {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10%;
  font-size: 1rem;
}

.profile-info-table-col-1 {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 0.75rem;
}

.profile-info-table-col-2 {
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 0.75rem;
}

.profile-info-table-header {
  font-weight: 600;
}

.profile-info-table-value {
  /* grid-column: 2 / 3; */
}
