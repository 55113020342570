.schedule-pickup {
  height: 100%;
  padding: 10px 0;
}

.schedule-pickup-button {
  /* padding-top: 100px; */
}

.schedule-pickup-phone {
  margin-top: 0.75rem;
  font-size: 1.2rem;
  font-weight: 600;
}
