.module {
  display: flex;
  /* min-width: 250px; */
  max-width: 1200px;
  background-color: #FFFFFF;
  border-radius: 20px;
  text-align: left;
}

.module-color-stub {
  width: 20px;
  background-color: #4D4D4D;
  border-radius: 20px 0 0 20px;
  z-index: 2;
}

.module-loader {
  display: none;
}

.module-loader-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.module-inner {
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 20px;
}

.module-inner p:last-child {
  margin-bottom: 0;
}

.module-inner-loading {
  display: none;
}

.module h1 {
  margin: 0 0 16px 0;
  font-size: 1.6rem;
  font-weight: 500;
  /* color: #2E2E2E; */
  color: #4D4D4D;
}

.module-subtitle {
  font-size: 1.2rem;
}
