.select-wrapper {
}

.select-wrapper select {
  font-size: 1.2rem;
}

.select-wrapper select option {
  
}
