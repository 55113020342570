.roles {
  padding: 10px 0 24px 0;
}

.roles-list {
  display: flex;
  flex-wrap: wrap;
}

.roles-list-role {
  display: inline-flex;
  padding: 12px;
  background-color: #7A2DBB;
  color: #FFFFFF;
  border-radius: 25px;
}
