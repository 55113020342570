.modal-blocker {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}

.modal-close-button {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 30px;
  color: red;
  cursor: pointer;
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 800px;
  height: 600px;
  z-index: 10001;
}

.modal-content {
  height: 100;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow-y: scroll;
}
