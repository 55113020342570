.reporting {
  padding: 10px 0 24px 0;
}

.reporting-table {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 0.75rem;
  font-size: 1rem;
}

.reporting-table-header {
  grid-column: 1 / 2;
}

.reporting-table-value {
  grid-column: 2 / 3;
}
