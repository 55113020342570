.button-wrapper {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  /* padding: 10px 20px; */
  min-width: 150px;
  min-height: 36px;
  border-radius: 10px;
  background-color: #3bb54a;
  cursor: pointer;
}

.button-text {
  color: #FFFFFF;
  font-weight: 500;
}
