.link-wrapper {
  /* margin-bottom: 16px; */
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.link-wrapper .icon {
  margin-right: 6px;
}

.link-text {
  color: #3bb54a;
  font-weight: 500;
  text-decoration: none;
}

.link-text:visited {
  color: #3bb54a;
}
