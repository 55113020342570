*, *:before, *:after, html {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif;
  background-color: #E3E3E3;
  overscroll-behavior: none;
}

a {
  color: #3bb54a;
}

a:visited {
  color: #3bb54a;
}

/* For when we need to nest components with div children */
.p {
  margin: 0.5rem 0;
}

#loading-blocker {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.70);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen {
  background-color: #E3E3E3;
  /* min-height: 100vh; */            /* NEW */
  height: 100%;                       /* NEW */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: #676767;
}

.fullscreen-footer {
  margin-top: auto;
}

.logo-esb {
  width: 150px;
  height: 150px;
  margin: 20px;
}

.card-main {
  background-color: #FFFFFF;
  min-height: 500px;
  width: 400px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: auto; */             /* NEW */
  align-self: center;                 /* NEW */
}

.card {
  background-color: #FFFFFF;
  width: 250px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* margin-top: auto; */
}

.app-layout {
  position: relative;                 /* NEW */
  background-color: #E3E3E3;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  color: #676767;
  text-align: center;
}

.screen-content {
  display: table;
  border-collapse: collapse;
  background-color: #E3E3E3;
  min-height: 100vh;
  flex-grow: 1;
  /* width: 100%; */
}

.screen-name {
  position: absolute;
  margin-top: 20px;
  float: left;
  clear: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #FFFFFF;
  font-size: 1.6rem;
  padding: 14px 26px 14px 20px;
  border-radius: 0 15px 15px 0;
}

/* .screen-name-icon {
  height: 44px;
  padding-right: 16px;
} */

.screen-name-text {
  padding-left: 16px;
}

.screen-modules {
  background-color: #E3E3E3;
  height: auto;
  width: auto;
  max-width: 1200px;
  padding: 0 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin: 120px auto auto auto;
}

.app-layout-footer {
  height: 1px;
  border-top: solid transparent 20px;
  border-bottom: solid transparent 20px;
  display: table-row;
  vertical-align: bottom;
}


/* Fonts */
/* -------------------------------------------------------------------------- */
/* @font-face {
  font-family: 'Muli';
  font-weight: 400;
  font-style: normal;
  src: url('https://fonts.gstatic.com/s/muli/v10/BkuZCUxEYxRfSu-XBj9_CA.eot');
  src: url('https://fonts.gstatic.com/s/muli/v10/BkuZCUxEYxRfSu-XBj9_CA.eot?#iefix') format('embedded-opentype'),
       local('Muli Regular'),
       local('Muli-regular'),
       url('https://fonts.gstatic.com/s/muli/v10/zscZFkjVRGyfQ_Pw-5exXPesZW2xOQ-xsNqO47m55DA.woff2') format('woff2'),
       url('https://fonts.gstatic.com/s/muli/v10/minRpKQdEvXRRS8oAbAtWvesZW2xOQ-xsNqO47m55DA.woff') format('woff'),
       url('https://fonts.gstatic.com/s/muli/v10/BfQP1MR3mJNaumtWa4Tizg.ttf') format('truetype'),
       url('https://fonts.gstatic.com/l/font?kit=5laWPvb-IgmGJk9r92y1Hw&skey=2b55aa3f2f059b75&v=v10#Muli') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 700;
  font-style: normal;
  src: url('https://fonts.gstatic.com/s/muli/v10/cCFWp1Mlox-aYr8DesZNrw.eot');
  src: url('https://fonts.gstatic.com/s/muli/v10/cCFWp1Mlox-aYr8DesZNrw.eot?#iefix') format('embedded-opentype'),
       local('Muli Bold'),
       local('Muli-700'),
       url('https://fonts.gstatic.com/s/muli/v10/4CQLgXecGZ8f_sbf4m6a6gLUuEpTyoUstqEm5AMlJo4.woff2') format('woff2'),
       url('https://fonts.gstatic.com/s/muli/v10/Rl-5CtBBvmtOyT-alcFUZgLUuEpTyoUstqEm5AMlJo4.woff') format('woff'),
       url('https://fonts.gstatic.com/s/muli/v10/zDo7ROEZZCd7eFZ9jdOP8g.ttf') format('truetype'),
       url('https://fonts.gstatic.com/l/font?kit=Eb_E7qc_5Ymc1CSidH9ZIw&skey=76d4d6b6c5f88b12&v=v10#Muli') format('svg');
} */

/* -------------------------------------------------------------------------- */









/* Temp Styles */
/* ---------------------------------------------------------------------------*/
input {
  max-width: 500px;
}

.gray {
  color: #828282;
}

.orange {
  background-color: #ff6600;
}

.background-gray {
  background-color: rgb(246,246,239);
}

.f11 {
  font-size: 11px;
}

.w85 {
  width: 85%;
}

.button {
  /* font-family: monospace; */
  font-size: 10pt;
  color: black;
  background-color: buttonface;
  text-align: center;
  padding: 2px 6px 3px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  cursor: pointer;
  max-width: 250px;
}
