.preferences {
  padding: 10px 0 24px 0;
}

.preferences-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.preferences-icon {
  position: relative;
  margin-right: 20px;
}

.preferences-table {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 0.75rem;
  font-size: 1rem;
}

.preferences-table-header {
  grid-column: 1 / 2;
  font-weight: 600;
}

.preferences-table-value {
  grid-column: 2 / 3;
}
