.impact-summary {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}

.impact-summary-stats {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1rem;
}

.impact-summary-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.impact-summary-table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 300px auto;
  grid-row-gap: 0;
}

.impact-summary-table-row p {
  margin: 0.6rem 0.6rem 0.6rem 0;
}

.impact-summary-header {

}

.impact-summary-stat {
  font-weight: 700;
}

.impact-summary-media, .impact-summary-media img {
  background-color: #eeeeee;
  width: 300px;
  min-width: 300px;
  height: 200px;
  border-radius: 10px;
}
