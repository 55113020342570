.login-h1 {
  margin-top: 0;
}

.g-signin2 {
  margin-bottom: 16px;
}

.fb-login-button {
  margin-bottom: 16px;
}

.login-or {
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: 600;
}
