/* .authorized-users-add-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  font-size: 0.8rem;
  font-weight: 700;
  color: #3B88FD;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #707070;
}

.authorized-users-add-logo p {
  margin: 6px 0 0 0;
}

.authorized-users-add-logo .icon {
  margin: 0 0 0 0;
} */

.authorized-users-logo {
  height: 40px;
  width: 40px;
  border-radius: 100px;
}
