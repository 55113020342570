.active-property {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.active-property .module-subtitle {
  padding-right: 20px;
}

.active-property-select {  /* TODO: Change to ".active property select" */
  padding-right: 20px;
}
