.production-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.production-data-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.production-data-tools-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-right: 26px;
  cursor: pointer;
}

.production-data-tools-item img {
  margin-right: 6px;
}

.production-data-select-hub {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.production-data-select-hub p {
  font-weight: 700;
  margin-right: 12px;
}

.production-data-stats {
  height: 100%;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1rem;
  margin-right: 40px;
}

.production-data-table {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 6px;
}

.production-data-table-header {
  font-weight: 700;
  border-bottom: black solid 3px;
}

.production-data-table p {
  margin: 0;
}

.production-data-units {
  font-size: 0.8rem;
  color: #a1a1a1;
}

.production-data-export-modal {

}

.production-data-export-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.production-data-export-modal-row p {
  margin-right: 10px;
}

.production-data-export-modal-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.production-data-export-modal .button-wrapper {
  margin-right: 10px;
}
