.sales-report {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.sales-report-stats {
  height: 100%;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1rem;
  margin-right: 40px;
}

.sales-report-select-hub {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.sales-report-select-hub p {
  font-weight: 700;
  margin-right: 10px;
}

.sales-report-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sales-report-table-section {
  margin-bottom: 1.25rem;
}

.sales-report-table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 200px 60px auto;
  grid-row-gap: 0;
}

.sales-report-table-row p {
  /* margin: 0.6rem 0.6rem 0.6rem 0; */
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
}

.sales-report-header {
  /* font-weight: 700; */
}

.sales-report-stat {

}

.sales-report-units {
  font-size: 0.8rem;
  color: #a1a1a1;
}

.sales-report-chart {
  display: flex;
  align-self: center;
  flex-direction: column;
  /* width: 300px;
  min-width: 300px;
  height: 200px; */
  /* border: 1px solid #a5a5a5;
  border-radius: 10px; */
}

.sales-report-chart-img {
  background-color: #ffffff;
  width: 300px;
  min-width: 300px;
  height: 200px;
  border: 1px solid #a5a5a5;
  border-radius: 10px;
}
