.database-mod {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.database-mod-collections {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}

.database-mod-collection {
  position: relative;
  margin: 0 20px 0 0;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.database-mod-collection-accent {
  position: absolute;
  background-color: #5a5a5a;
  border-radius: 10px 0 0 10px;
  height: 100%;
  width: 8px;
}

.database-mod-collection-accent-active {
  position: absolute;
  background-color: #3bb54a;
  border-radius: 10px 0 0 10px;
  height: 100%;
  width: 8px;
}

.database-mod-collection-name {
  padding: 14px 18px 14px 24px;
  font-size: 1.25rem;
}

.database-mod-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.database-mod-tools-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-right: 16px;
  cursor: pointer;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.database-mod-tools-item img {
  margin-right: 6px;
}

.database-mod-stats {
  height: 100%;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1rem;
  margin-right: 40px;
}

.database-mod-table {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 6px;
}

.database-mod-table-header {
  font-weight: 700;
  border-bottom: black solid 3px;
}

.database-mod-table p {
  margin: 0;
}

.database-mod-units {
  font-size: 0.8rem;
  color: #a1a1a1;
}

.database-mod-export-modal {

}

.database-mod-export-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.database-mod-export-modal-row p {
  margin-right: 10px;
}

.database-mod-export-modal-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.database-mod-export-modal .button-wrapper {
  margin-right: 10px;
}
