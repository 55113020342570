.late-payment {
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; */
}

.late-payment .module-subtitle {
  display: inline;
  font-size: 1rem;
  color: #FF0000;
  font-weight: 600;
  padding-right: 10px;
}
