.support {
  display: table-row;
  vertical-align: center;
}

.support h1 {
  color: #707070;
  font-size: 2.75rem;
  margin: 0;
}

.support p {
  font-size: 1.1rem;
}

.support a {
  font-size: 1.75rem;
  font-weight: 600;
  text-decoration: none;
}
